import { callOnAuthChange, getCurrentUsersScoresForDate, addScoreForAuthUser, editScoreForAuthUser, removeScoreForAuthUser} from "./firebase"

console.log("HELLO WORLD")
const currentDateLabel = document.getElementById("currentDateLabel")
setDateLabel(new Date());

function setDateLabel(date = new Date()) {
    const dateStrOpts = { year: "numeric", month: "long", day: "numeric" };
    const dateStr = date.toLocaleDateString([], dateStrOpts);
    currentDateLabel.innerText = `Your scores for ${dateStr}`;
}

const addScoreButton = document.getElementById("add-score-btn");
const addScoreGameSelect = document.getElementById("new-score-game");
const addScoreScoreInput = document.getElementById("new-score-input");

const editScoreModal = document.getElementById("editScoreModal");
const editScoreLabel = document.getElementById("editScoreLabel");
const editScoreInput = document.getElementById("editScoreInput");
const editScoreButton = document.getElementById("editScoreBtn");
const removeScoreModal = document.getElementById("removeScoreModal");
const removeScoreLabel = document.getElementById("removeScoreLabel");
const removeScoreButton = document.getElementById("removeScoreBtn");

addScoreButton.addEventListener("click", addNewScore);
editScoreButton.addEventListener("click", editScore);
removeScoreButton.addEventListener("click", removeScore);

function addNewScore() {
    const gameDocName = `games/${addScoreGameSelect.options[addScoreGameSelect.selectedIndex].getAttribute("data-gameref")}`
    const scoreForScore = parseInt(addScoreScoreInput.value);

    console.log(gameDocName + ": " + scoreForScore);
    addScoreButton.classList.add("disabled");
    addScoreButton.setAttribute("disabled", "true");

    addScoreForAuthUser(gameDocName, scoreForScore, new Date())
        .then((newDocId) => {
            if (newDocId != null) {
                addScoreToTable(gameDocToNameMap[gameDocName], scoreForScore, gameDocToUrl[gameDocName], newDocId);
            }
        })
        .catch((error) => {
            console.error(error.message);
        })
        .finally(() => {
            addScoreButton.classList.remove("disabled");
            addScoreButton.removeAttribute("disabled");
        });
}

function editScore() {
    const docId = editScoreModal.getAttribute("data-doc-id");
    const score = parseInt(editScoreInput.value);

    editScoreButton.classList.add("disabled");
    editScoreButton.setAttribute("disabled", "true");

    editScoreForAuthUser(docId, score)
        .then((result) => {
            if (result) {
                editScoreInTable(docId, score);
            }
        })
        .catch((error) => {
            console.error(error.message);
        })
        .finally(() => {
            editScoreButton.classList.remove("disabled");
            editScoreButton.removeAttribute("disabled");
            bootstrap.Modal.getInstance(editScoreModal).hide();
        });
}

function removeScore() {
    const docId = removeScoreModal.getAttribute("data-doc-id");

    removeScoreButton.classList.add("disabled");
    removeScoreButton.setAttribute("disabled", "true");

    removeScoreForAuthUser(docId)
        .then((result) => {
            if (result) {
                removeScoreFromTable(docId);
            }
        })
        .catch((error) => {
            console.error(error.message);
        })
        .finally(() => {
            removeScoreButton.classList.remove("disabled");
            removeScoreButton.removeAttribute("disabled");
            bootstrap.Modal.getInstance(removeScoreModal).hide();
        })
}

//this basically only exists to save on firebase reads
//don't need to check the game docs
//might later to verify real game scores are being submitted
const gameDocToNameMap = {
    "games/wordle": "Wordle",
    "games/framed": "Framed",
    "games/globle": "Globle",
    "games/heardle": "Heardle",
    "games/nerdle": "Nerdle",
    "games/quordle": "Quordle",
    "games/semantle": "Semantle",
    "games/worldle": "Worldle"
}

const gameDocToUrl = {
    "games/wordle": "https://www.nytimes.com/games/wordle/index.html",
    "games/framed": "https://framed.wtf/",
    "games/globle": "https://globle-game.com/",
    "games/heardle": "https://www.heardle.app/",
    "games/nerdle": "https://nerdlegame.com/",
    "games/quordle": "https://www.quordle.com/#/",
    "games/semantle": "https://semantle.com/",
    "games/worldle": "https://worldle.teuteuf.fr/"
}

function displaySelectedDateScores(authorized) {
    const scoreTableElement = document.getElementById("yourScoresTableBody");

    while (scoreTableElement.firstChild) {
        scoreTableElement.removeChild(scoreTableElement.firstChild);
    }

    if (authorized) {
        addScoreButton.classList.add("disabled");
        addScoreButton.setAttribute("disabled", "true");

        getCurrentUsersScoresForDate(new Date())
            .then((scoreData) => {
                if (scoreData != null) {
                    console.log("scores collected");
                    for (let i = 0; i < scoreData.length; i++) {
                        addScoreToTable(gameDocToNameMap[scoreData[i]["game"]], scoreData[i]["score"], gameDocToUrl[scoreData[i]["game"]], scoreData[i]["id"]);
                    }
                }
            })
            .catch((error) => {
                console.error(error.message);
            })
            .finally(() => {
                addScoreButton.classList.remove("disabled");
                addScoreButton.removeAttribute("disabled");
            });
    }
}

function addScoreToTable(gameName, gameScore, gameLink, docId) {
    // move this dude
    const scoreTableElement = document.getElementById("yourScoresTableBody");

    const rowTemplate = document.getElementById("scoreRowTemplate");
    const rowClone = rowTemplate.content.firstElementChild.cloneNode(true);
    rowClone.setAttribute("data-doc-id", docId);

    const gameNameEl = rowClone.querySelector("th a");
    gameNameEl.href = gameLink;
    gameNameEl.textContent = gameName;

    const gameDataEls = rowClone.querySelectorAll("td");
    // 0 -> score element
    // 1 -> actions element

    gameDataEls[0].textContent = gameScore;
    const gameRowButtons = gameDataEls[1].querySelectorAll("button");
    gameRowButtons[0].setAttribute("data-doc-id", docId);
    gameRowButtons[0].addEventListener("click", () => { openEditModal(gameName, gameScore, docId); });
    gameRowButtons[1].setAttribute("data-doc-id", docId);
    gameRowButtons[1].addEventListener("click", () => { openRemoveModal(gameName, docId); });

    scoreTableElement.appendChild(rowClone);
}

function editScoreInTable(docId, newScore) {
    const scoreTableElement = document.getElementById("yourScoresTableBody");
    const tableRows = scoreTableElement.children;

    for (let i = 0; i < tableRows.length; i++) {
        if (tableRows[i].getAttribute("data-doc-id") === docId) {
            const scoreData = tableRows[i].getElementsByTagName("td")[0];
            scoreData.textContent = newScore;
            break;
        }
    }
}

function removeScoreFromTable(docId) {
    const scoreTableElement = document.getElementById("yourScoresTableBody");
    const tableRows = scoreTableElement.children;

    for (let i = 0; i < tableRows.length; i++) {
        if (tableRows[i].getAttribute("data-doc-id") === docId) {
            scoreTableElement.removeChild(tableRows[i]);
            break;
        }
    }
}

function openEditModal(gameName, currentScore, docId) {
    const modalInst = new bootstrap.Modal(editScoreModal);

    editScoreModal.setAttribute("data-doc-id", docId);
    editScoreLabel.textContent = `Enter new score for ${gameName}`;
    editScoreInput.value = currentScore;

    modalInst.show();
}

function openRemoveModal(gameName, docId) {
    const modalInst = new bootstrap.Modal(removeScoreModal);

    removeScoreModal.setAttribute("data-doc-id", docId);
    removeScoreLabel.textContent = `Are you sure you want to delete your score for ${gameName}?`;

    modalInst.show();
}

callOnAuthChange(displaySelectedDateScores);
